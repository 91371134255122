@import './color.scss';
.about{
    position: relative;
    min-height: 100vh;
    background: $background-gradient;
    @media screen and (min-width:600px) {
        padding-bottom: 100px;
    }
    // padding-bottom:80px;
    // div.row{
    //     // padding: 20px;
    // }
    .about-section{
        margin: 25px auto;
        // width: 60%;
        padding: 30px;
        // background: $white-background;
        img{
            max-width:100%;
            height: 100%;
        }
    }
}