@import './color.scss';
.container-content{
    .container{
        @media screen and (min-width:900px) {
            max-width: 788px;
        }
        padding:0 20px;
    }
    .quest{
        @media screen and (min-width:700px) {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        }
        background: $white-background;
        padding:0 30px;
        margin-bottom: 80px;
        
        border-radius: 5px;
    }
}
.quest{
    position: relative;
    .next-arrow{
        @media screen and(max-width:1000px) {
            right: -15px;
        }
        position: absolute;
        font-size: 54px;
        top:0;
        bottom: 0;
        right: -124px;
        display: flex;
        align-items: center;
    }
}
.question-title{
    word-break: break-all;
}
.question2-image{
    width: 100%;
    // .img{
    //     max-width:100%;
    // }
}
.question2-image img{
    max-width: 100%;
    // height: 200px;
}