@import './color.scss';
.edit{
    position: relative;
    min-height: 100vh;
}
.edit-form{
    @media screen and (min-width:600px) {
        min-width: 400px;
    }
    margin: 50px auto;
    width: 95%;
    padding: 30px;
    input{
        border-radius: 25px;
    }select{
        border-radius: 25px;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
        -webkit-appearance: none;
        background-position-x: 96%;
    }
}
.save-button{
    background: $button-color !important;
    width:80% !important; 
    border-radius: 25px !important;
    border: 1px solid $button-color !important;
}
.cancel-button{
    background: $button-color !important;
    width:80% !important;
    border-radius: 25px !important;
    border: 1px solid $button-color !important;
}

@media screen and (min-width:600px){
    .edit{
        background: $background-gradient;
        padding-bottom:40px;
    }
    .edit-form{
        background-color: $white-background;
        width:30%;
        box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
        border-radius: 5px;
        padding:0 50px 50px 50px;
    }
    .save-button{
        background: $button-color !important;
        width:100% !important; 
        border-radius: 25px !important;
    }
    .cancel-button{
        background: $button-color !important;
        width:100% !important;
        border-radius: 25px !important;
    }
    // .edit-button{
    //     width: 40%;
    // }
}