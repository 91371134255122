@import './color.scss';
.selected{
    min-height: 100vh;
}
.selected-topic-section{
    width: 85%;
    margin:20px auto;
    padding: 10px;
    box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
    border-radius: 30px;
}
.selected-top{
    background-color: $topic-follow;
    margin:8px;
    padding:5px;
    border-radius: 10px;
}
.unselected-top{
    background-color: $topic-unfollow;
    margin:8px;
    padding:5px;
    border-radius: 10px;
}
.browse-button{
    background: $browse-button !important;
    border:none !important;
    box-shadow: 0px 13px 9px -10px rgba(112, 112, 112, 0.25) !important;
    border-radius: 19.5px !important;
}
.select-bottom-button{
    position:fixed;
    left:0;
    right: 0;
    bottom: 15px;
    padding-bottom:10px;
    width: 92%;
    margin: auto;
}
.followed{
    background-color: $topic-follow;
}
.unfollowed{
    background-color: $topic-unfollow;
}
@media screen and (min-width:700px){
    .selected{
        background: $background-color;
    }
    .selected-topic-section{
        width:56%;
        min-height:221px;
        padding: 20px;
        margin:60px auto;
        border-radius: 30px;
        background-color: $white-background;
    }
    .select-bottom-button{
        width: 28%;
    }
    .browse-button{
        width: 315px !important;
        height: 39px;
        margin: auto;
    }

}