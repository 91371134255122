@import './color.scss';
.loader{
    // background:rgba(255,255,255,0.1);
    background: $background-gradient;
}
.loading{
    height:11px;
    width:200px;
    border:10px;
    margin:auto;
    align-items: center;
    border-radius: 45px;
    border: 2px solid $logo-theme-color;
    position: relative;
}
.loading:before{
    position: absolute;
    content:"";
    height:55px;
    width: 18px;
    // background-color:red;
    top:14px;
    right:-22px;
    border-radius: 0 5px 5px 0px;
}
.loading:after{
    position: absolute;
    content:"";
    height:5px;
    width: 1px;
    background-color:$logo-theme-color;
    top:1px;
    left:1px;
    border-radius:25px;
    animation: charge 2s infinite linear;
}
@keyframes charge{
    100%{
        width:194px;

    }
}
