@import './color.scss';
.login{
    position: relative;
    // background: $background-color;
    // padding-bottom: 150px;
    min-height: 100vh;
}
.welcome{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #0A191E;
}
.login-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}
.login-form{
    @media screen and (min-width:600px) {
        min-width: 400px;
    }
    // width:80%;
    padding:0 30px;
    margin:115px auto 0 auto;
    justify-content:space-between;
    // text-align: center;
    & input{
        border-radius:100px;
        background-color:$input-background-color;
        border: 1px solid rgba(0, 0, 0, 0.13);
        // height: 53px;
        padding:17px 30px;
    }

    button{
        padding:8px 0px;
    }
}
.form-group>label {
    bottom: 34px;
    left: 15px;
    position: relative;
    background-color:$input-background-color;
    padding: 0px 5px 0px 5px;
    font-size: 1.1em;
    transition: 0.2s;
    pointer-events: none;
}
  .form-control:focus~label {
    bottom: 55px;
  }
  
  .form-control:valid~label {
    bottom: 55px;
  }
.login-button{
    background: $button-color !important;
    border:none !important;
    border-radius:25px !important;
}
.login-forgot-button{
    line-height: 19px;
    color: #0A191E !important;
}
.account{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size:14px; 
}

.facebook{
    background:none;
    border:none;
    color:blue;
    // background-color: red;
    height:42px;
    width: 42px;
    border-radius:25px;
    opacity: 0.7 !important;
    border: 1px solid #3A58BA !important;
    box-sizing: border-box;
    margin-left: -20px;
    margin-right: 20px;
}
.login-signup-link{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: $button-color !important;
}
.google{
    background:none !important;
    border:none !important;
    color:none !important;
    height:42px;
    width:42px;
    // border-radius: 25px !important;
    div{
        background: none !important;
        border: none !important;
        border-radius: 25px !important;
    }

}
svg{
    background: none;

}
button.google{
    border-radius: 25px !important;
    box-sizing:unset !important;
    opacity: 0.7 !important;
    border: 1px solid #D6281D !important;
    box-sizing: border-box !important;
    padding:2px !important;
}
// eye left border-disable
.input-group .form-control {
    border-right: none;
}
@media screen and (min-width:600px){
    .login{
        background: $background-gradient;
        padding-bottom: 90px;
    }
    .login-form{
        width:30%;
        padding:50px 50px 30px 50px;
        background-color: white;
        box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
        border-radius: 30px;
        margin:40px auto 0 auto;
    }
}