@import './color.scss';
.forgot-password{
    position: relative;
    background: $background-gradient;
    height:100vh;
}
.password-link {
    display:flex;
    justify-content: center;
    align-items: center;
}
.password-form{
    @media screen and (min-width:600px) {
        max-width: 435px;
    }
    width:80%;
    margin:100px auto;
    input{
        border-radius:25px;
    }
    .forgot-button{
        background:$button-color !important;
        // color:$button-color !important;
        border:none !important;
        border-radius:25px;
        width: 80%;
        margin: auto;
    }
    .password-eye{
        background: none !important;
    }
}
.forgot-password-heading {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height:40.85px;
}
.recovery-link-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400px;
    font-size: 14px;
    line-height: 17.07px;
}
// change password screen
.showform{
    display:block;
    min-height: 150px;

}
.showerror{
    display: none;
}
.change-password{
    background: $button-color !important;
    border:none !important;
    border-radius: 100px !important;
}
@media screen and (min-width:600px){
    .forgot-password{
        background:$background-gradient;
    }
    .password-form{
        // width:30%;
        background: #fff;
        padding: 25px 50px;
        box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
        border-radius: 30px;
        margin: 50px auto;
    }
    .forgot-button{
        width: 55% !important;
    }
    .change-password{
        width:60% !important;
    }
}