@import './color.scss';
.user{
    position: relative;
    min-height: 100vh;
}
.user-detail{
    @media screen and (min-width:600px) {
        min-width: 400px;
    }
    // width: 80%;
    margin: auto;
    margin-top: 10px;
    margin: 0 10px;
    // margin-left:20px;
    button{
        background: $button-color !important;
        border-radius: 25px;
        border: 1px solid $button-color !important;
        &:hover{
            border: 1px solid $button-color ;
        }
    }
}
.edit-button{
    background: $button-color !important;
    border: none !important;
    width:80% !important;
    border-radius: 5px !important;
    margin: auto;
    // // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 15px;
}
.tl{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.tr{
    padding-left: 20px !important;
    padding-right: 0px !important;
}
@media screen and (min-width:700px){
    .user{
        background: $background-gradient;
        padding-bottom: 20px;
    }
    .user-detail{
        background: $white-background;
        width: 32%;
        margin:60px auto;
        box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
        border-radius: 5px;
        padding: 0 40px 20px 40px;
        .tl{
            padding-left: 0px !important;
            padding-right: 0 !important;
        }
        .tr{
            padding-left: 0px !important;
            padding-right: 5px !important;
        }
    }
    .edit-button{
        background: $button-color !important;
        border: none !important;
        width:60% !important;
        border-radius: 5px !important;
        margin: auto;
    }
}