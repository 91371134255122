// @import 'F:/Auriga_QuizApp/quiz-app-frontend/node_modules/bootstrap/scss/bootstrap.scss';
// @import 'F:/Auriga_QuizApp/quiz-app-frontend/node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import './assets/styles/color.scss';
@import './assets/styles/common.scss';
@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import './assets/styles/signup.scss';
@import './assets/styles/question.scss';
@import './assets/styles/login.scss';
@import './assets/styles/selecttopic.scss';
@import './assets/styles/topicpage.scss';
@import './assets/styles/landingpage.scss';
@import './assets/styles/selectedtopic.scss';
@import './assets/styles/forgotpassword.scss';
@import './assets/styles/user.scss';
@import './assets/styles/edit.scss';
@import './assets/styles/home.scss';
@import './assets//styles/subtopic.scss';
@import './assets/styles/topictab.scss';
@import './assets/styles/createquiz.scss';
@import './assets/styles/footer.scss';
@import './assets/styles/about.scss';
@import './assets/styles/loader.scss';
@import './assets/styles/question2.scss';
@import './assets/styles/header.scss';
@import './assets/styles/changepassword.scss';

// .t-b{
//   width:80%;
//   background-color: red;
//   margin:20px auto;
//   text-align: center;
//   border-radius: 20px;

// }
// .ran{
//   background-color: blue;
// }
// .rev{
//   background-color: green;
// }







// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
