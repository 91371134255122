@import './color.scss';
.home{
    position: relative;
    min-height:100vh;
    // padding-bottom:100px ;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}
.banner-section{
    padding: 30px 0 80px 0px;
}

.home-heading + div{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.logo{
    width: 150px;
    height: 150px;
}
.logo-div{
    display: block;
}
.home-bottom{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom:10px;
}
.home-header{
    // margin: 40px;
    div{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        line-height:21.79px;
        div>div{
            font-size:34px;
            line-height:46.3px;
        }
    }
    img{
        max-width: 100%;
    }
}
.home-section{

    @media screen and (min-width: 1000px) {
        max-width: 910px;
    }

    // div.row{
    //     padding:10px 20px 10px 20px;
    //     // margin-left: 10px;
    //     // margin-right: 10px;
    // }
    i{
        position: absolute;
        right:40px;
        top:20px;
        font-size:28px;
    }
}
.home-heading{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size:36px;
}
.home-heading +p{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 18px;
}
.topic-name {
    width: 92%;
}
@media screen and (min-width:600px){
    .home{
        background: $background-gradient;
        padding-bottom:80px;
    }
    .logo{
        height: 250px;
        width: 250px;
    }
    .logo-div{
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .banner-section{
        background-color: rgba(255,253,247,0.5);
        margin-bottom:30px;
    }
    .home-header{
        // width: 90%;
        // margin: auto;
        div{
            justify-content: center;
            align-items: center;
           
        }
        img{
            width: 100%;
        }
    }
    .home-section{
        margin: auto;
        // padding: 8px;
        div.topic{
            // width:100%;
            // border-radius: 10px;
            i{
                position: absolute;
                right:22px;
                top:20px;
                font-size:28px;
            }
            // p {
            //     width: 400px;
            // }
            span{
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                color:#4A4A4A;
                font-size:16px;
            }
        }
    }
}