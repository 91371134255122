@import './color.scss';
.landing{
  min-height: 100vh;
}
.div-button{
  position:fixed;
  left:0;
  right: 0;
  bottom: 15px;
    width:40%;
    background-color: $play-button;
    height:40px;
    border-radius: 20px;
    margin:20px auto;
    text-align: center;
    padding-top: 7px;
  }
  .toggle-button{
    // display:inline;
    flex-direction: column;
    border: 3px solid $toggle-background;
    justify-content: space-around;
    width: 80%;
    background-color: $toggle-background;
    height:40px;
    border-radius: 20px;
    margin:20px auto !important;
    text-align: center;
    // padding:7px;
  }
    .random{
      // background-color: red;
      height: 40px;
      border-radius: 20px;
      padding: 5px;
    }
    .revision{
      background-color: blue;
      height: 40px;
      border-radius: 20px;
      padding-top: 5px;
    }
  // .container{
  //     height: 100px;
  //     width: 50%  !important;
  //     background: $toggle-background;
  //     box-shadow:
  //     -10px -10px 15px rgba(255,255,255,1),
  //     10px 10px 15px rgba(70,70,70,1);
  //     display:flex;
  //     align-items:center;
  //     justify-content: space-around;
  //     position: absolute;
  //     margin: auto;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     border-radius: 50px;
  //     input{
  //       display: none;
  //   }
  //   label{
  //       font-family: "Poppins",sans-serif;
  //       font-size: 27px;
  //       padding: 15px 35px;
  //       font-weight: 600;
  //       color: #303030;
  //       border-radius: 35px;
  //   }
  //   input:checked + label{
  //       background: saddlebrown;
  //     box-shadow:
  //     -10px -10px 15px rgba(201, 17, 17, 0.55),
  //     10px 10px 15px rgba(60, 223, 11, 0.12);
  //   }
  // }
  

// .switch{
//   background-color: $toggle-background;
//   border-radius: 25px;
//   margin: 0;
// }
.switch{
  
  border: 1px solid red;
}
  .quality {
    position: relative;
    display: inline-block;
    width: 50% ;
    margin:20px auto;
    height: 100%;
    line-height: 40px;
    // border-radius:25px;
    
    // box-shadow: 0 0 0 5px $toggle-background;

  }
  .quality:first-child label {
    border-radius: 25px 0 0 25px;
  }
  .quality:last-child label {
    border-radius: 0 25px 25px 0;
  }
  .quality label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border-radius:25px;
    cursor: pointer;
    font-style: italic;
    text-align: center;
    transition: transform 0.4s, color 0.1s, background-color 0.4s;
  }
  .quality input[type="radio"] {
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
  }
  .quality input[type="radio"]:focus {
    outline: 0;
    outline-offset: 0;
  }
  .quality input[type="radio"]:checked ~ label {
    background-color: $toggle-active;
    color: #111;
  }
  .quality input[type="radio"]:active ~ label {
    transform: scale(1.05);
  }
  
  
//   .container1{
//       height: 100px;
//       width:80%;
//       background: grey;
//       display:flex;
//       align-items:center;
//       justify-content: space-around;
//       position: absolute;
//       margin:500px auto;
//       top: 0;
//       bottom: 0;
//       left: 0;
//       right: 0;
//       border-radius: 50px;
//   }
//   .ran{
//       font-family: "Poppins",sans-serif;
//       font-size: 27px;
//       padding: 15px 35px;
//       font-weight: 600;
//       color: red;
//       border-radius: 35px;
//   }
//   input:checked + span{
//     background: saddlebrown;
//   box-shadow:
//   -10px -10px 15px rgba(201, 17, 17, 0.55),
//   10px 10px 15px rgba(60, 223, 11, 0.12);
// }
@media screen and (min-width:700px){
    .landing{
      background: $background-color;
    .landing-box{
      width: 60%;
      margin: 20px auto;
      padding: 20px;
      background: #fff;
      box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
      // border-radius: 30px;
    }
    }
    .toggle-button{
      width: 70%;
    }
    .div-button{
      width: 28%;
    }
}