@import './color.scss';
.navbar-container {
  background: $white-background;
}
.navbar{
    justify-content: flex-start !important;
  
  }
  div.navbar-nav{
    flex-direction: column-reverse;
  }
  .icon{
    cursor: pointer;
  }
  .terms{
    min-height: 100vh;
    background: $background-color;
  }
  .bold{
    color:$Tread-color;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
  }
  .riser{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
  #dropdown-menu-align-right{
    background-color:$background-color;
    color:black;
    border:none;
  }
  .nav-button{
    margin-top: -7px;
  }
  .custom-navbar{
    align-items: center;
    justify-content: flex-end;
  }
  a.nav-link{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin:0 18px;
    color:#373737;
  }
  button.navbar-toggler.collapsed{
    border: none;
  }
  .navigation-bar{
    background: $nav-shrink-color;
  }
  .navbar-button-primary{
    background: $button-new-color !important;
    border:none  !important;
    border-radius: 3px !important;
  }
  
  //////////Terms
  .term{
    // min-height: 100vh;
    background: $white-background;
    .terms-section{
      h2{
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
      }
      // width: 50%;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      margin: 40px;
    }
  }
  .page-not-found{
    min-height: 100vh;
    position: relative;

  }
  .loader{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 100vh;
  }
  @media screen and (min-width:700px){
    .term{
      min-height: 100vh;
      background: $background-color;
      padding-bottom: 20px;
      .terms-section{
        width: 50%;
        margin: 40px auto;
      }
    }
    .navigation-bar{
    // padding-left: 98px !important;
    // padding-right: 90px !important;
    // padding-top: 15px !important;
    padding: 15px 0;
    // background-color: rgba(255,253,247,0.5);
    }
    .page-not-found{
      background: $background-gradient;
    }
    .not-found{
      min-height: 250px;
      // display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  