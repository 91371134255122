@import './color.scss';
.topicpage{
    position: relative;
    min-height: 100vh;
}
.bottom-button{
    background: $button-color !important;
    // border-radius: 25px !important;
    border:none !important;
}
.topicpage-topic-heading{
    display: flex;
    justify-content: space-between;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 27.24px;
    color:#4A4A4A;
    span{
        color:$logo-theme-color;
    }
}
.enabled-revision{
    pointer-events: visible;
    opacity: 1;
}
.disabled-revision{
    pointer-events: none;
    opacity:0.5;
}
.topicpage-description{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 21.79px;
}
.topicpage-subtopic-heading{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    // padding-left: 10px;
    span{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 12px;
    }
}
.topicpage-subtopics{
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
}
.helping-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16.34px;
    color: #5e5e5e;
}
// .topic-page-header{
//     margin-bottom: 45px;
// }
.topic-content{
    word-break:break-all;
}

.topic-button{
    // position:fixed;
    // left:0;
    // right: 0;
    // bottom:5px;
    // padding-bottom:10px;
    width: 92%;
    margin: auto;
}
.subtopic-label{
    // background-color: $subtopic-label;
    margin: 0 20px 0 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color:#4f4f4f;
    padding: 5px 15px !important;
    height: 35px;
    border-radius: 10px;
}
.subtopic-label-section{
    height:20px;
}
.custom-checkbox{
    position:relative;
    padding-left:10px;
}


.check input{
    opacity: 0;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmarks{
	position:absolute;
    left:0;
    top:5px;
    height: 18px;
  	width: 18px;
  	background-color: $logo-theme-color;
}
.checkmarks:before{
	content:"";
	position:absolute;
    left:2px;
    top:2px;
    height:14px;
    width:14px;
    background-color:white;
}
/* On mouse-over, add a grey background color */
// .checkmarks:hover{
// 	// background-color:#ccc;
// }
/* When the checkbox is checked, add a blue background */
input:checked ~ .checkmarks{
	background-color:white;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmarks:after{
	content:"";
    position:absolute;
    display:none;
}
/* Show the checkmark when checked */
input:checked ~.checkmarks:after{
	display:block;
}
/*border when input checked*/
input:checked ~.checkmarks:before{
	left:0;
    top:0;
    height:18px;
    width:18px;
	border:2px solid $logo-theme-color;
}
/* Style the checkmark/indicator */
.checkmarks:after{
	left:6px;
    top:3px;
    width:5px;
    height:10px;
    border:solid $logo-theme-color;
    border-width:0 2px 2px 0;
    transform:rotate(45deg);
    
}
  @media screen and(min-width:700px){
      .topicpage{
        //   background: $background-color;
        background: $background-gradient;
        padding-bottom:60px;

      }
      .topic-page-header{
          width:65%;
          background-color:$white-background;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
          border-radius: 5px;
          margin:25px auto;
          padding:0 20px 10px 25px;
        //   border:1px solid green;
        //   box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
        //   border-radius: 30px;
          min-height:250px;
      }
      .topic-button{
          display: flex;
          width: 90%;
          position: relative;
      }
      
  }