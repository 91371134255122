@import './color.scss';
.footer{
    text-align: center;
    width:94%;
    margin: auto;
    padding: 10px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height:21.79px;
    
}
@media screen and (min-width:600px){
    .footer{
        position: absolute;
        width:100%;
        bottom:0;
        background:$footer-background ;
        height: 50px;  
        .footer-row{
            // width:90%;
            padding: 10px 98px;
            margin: auto;
        }
        // align-items: center;
        // display: flex;
        // justify-content: space-around;
    }
}