@import './color.scss';
.signup{
    // background: $background-color;
    position:relative;
    min-height:100vh;
}
.signup-form{
    width:80%;
    margin:20px auto;
    & input{
        border-radius: 100px;
        border: 1px solid rgba(0, 0, 0, 0.13);
        // background-color: $input-background-color;
        // height:53px;
        
    }
    .password-eye{
        background: none !important;
    }
    select{
        border-radius: 100px;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
        -webkit-appearance: none;
        background-position-x: 96%;
        // height: 53px;
    }
    .signup-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
    }
}
.password-eye{
    border-radius: 0px 100px 100px 0 !important;
    background-color:$input-background-color !important;
}
.signup-bottom{
    width: 132px;
    margin :auto;
}
.signup-button{
    width: 135px;
    border-radius: 25px !important;
    border: none !important;
    background-color: $button-color !important;
}
a.active_class {
    border-bottom:2px solid red;
    // justify-content: space-evenly;
}
.policies{
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
}
@media screen and (min-width:700px){
    .signup{
        background: $background-gradient;
        padding-bottom:40px;
    }
    .signup-form{
        background-color: #ffffff;
        width:53%;
        // border:1px solid red;
        margin:20px auto 40px auto;
        padding:20px 50px 40px 50px;
        box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
        border-radius: 30px;
    }
    .signup-bottom{
        width: 195px;
    }
}
