@import './color.scss';
.subtopic{
    position: relative;
    min-height: 100vh;
    padding-bottom: 20px;
}
.subtopic-heads {
    display: flex;
    justify-content: space-between;
}
.subtopic-parent{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
}
.subtopic-heading{
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: bold;
}
.subtopic-description{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}
.subtopic-header{
    padding: 20px;
    word-break: break-all;
    // margin-bottom:80px;
}
// .play-button{
   
//     border-radius: 100px;
// }
.button-groups{
    display: block;
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 15px;
    margin: auto;
    width: 90%;
    .play-button{
        background-color: $button-color !important;
        border: none;
        // border-radius: 19.5px;
        height: 39px;
    }
    .create-quiz-button{
        background-color:#fff !important;
        background: none;
        // border-radius: 19.5px;
        color:$button-color !important;
        border:1px solid $button-color !important;
        height: 39px;
    }
}
.subtopic-footer{
    position:absolute;
    left:0;
    right:0;
    bottom:5px;
}
@media screen and (min-width:700px){
    .subtopic{
        background:$background-gradient;
        .button-groups{
            position: relative;
            display: flex;
            width: 50%;
            margin: auto;
        }
        // .play-button{
        //     background-color: $button-color !important;
        //     border:none;
        //     border-radius: 19.5px;
        // }
        // .create-quiz-button{
        //     background: none !important;
        //     color: $button-color !important;
        //     border:1px solid $button-color !important;
        //     border-radius: 19.5px;
            
        // }
    }
}