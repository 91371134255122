@import './color.scss';
.changepassword{
    min-height: 100vh;
    @media screen and (min-width:600px) {
        background: $background-gradient;
    }
}
.changepassword-container{
    width:100%;
    padding: 10px 10px 20px 10px;
    margin:60px auto;
    @media screen and (min-width:600px) {
        max-width: 431px;
        background: $white-background;
        border-radius: 5px;
        box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
        // padding: 30px;
    }
    input{
        border-radius: 25px;
    }
    button{
        background: $button-color !important;
        border-radius: 25px;
        border: 1px solid $button-color !important;
        &:hover{
            border: 1px solid $button-color ;
        }
    }
}