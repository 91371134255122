@import './color.scss';
.topictab{
    min-height: 100vh;
    padding-bottom:30px;
    
    
}

.topictab-inside-div{
    // background-color: red;
    width:100%;
    margin:10px auto;
    ul{
      list-style-type:none;
    }
}
.topic-row{
  margin: 0 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.myBorder {
    border-left: 1px solid $topic-tab-color;
    margin-left: 40px;
    padding:2px;
    margin-top: -18px;
  }
.parent li{
  color: black;
}
  .parent {
    display:flex;
    margin-left: 25px;
    margin-bottom: -15px;
    background-color:$topic-tab-color;
    width:82%;
    height:60px;
    align-items:center;
    // border-radius: 10px;
    padding-left: 10px;
    padding-top:14px;
  }
  .parent ul{
    padding-left: 10px !important;
  }
  .descendant li {
    padding: 30px 0px 30px 0px; 
    margin-left: 30px;
    background-color:$topic-tab-color;
    margin:25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    font-size: 14px;
    color: black;
    // border-radius: 7.5px;
    /* height:20px; */
  }
  
  .firstDescendant {
    padding-top: 0px;
  }
  .vertical-line{
    border:1px solid $topic-tab-color;
    width:42px;
    margin-top:-45px;
    margin-left:-2px;
  }
  // ul{
  //   list-style-type:none;
  // }
  .save-and-play-button{
      background-color: $button-color !important;
      width: 50%;
      border: none !important;
      border-radius:100px !important;
  }
.topics-tab-heading{
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  // padding-bottom:10px;
}
@media screen and (min-width:700px){
    .topictab{
        background: $background-color;
        padding-bottom: 50px;
    }
    .save-and-play-button{
        width: 20%;
    }
    .topictab-inside-div{
      // background-color: red;
      width:60%;
      margin:10px auto;
  }
  .parent{
    width: 87%;
  }
  .topics-tab-heading{
    font-size: 24px;
    padding-bottom: 10px;
  }
}