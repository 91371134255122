@import './color.scss';
.topic-page{
  position: relative;
  min-height: 100vh;
  // padding-bottom:80px;
}
.topic-section{
  @media screen and (min-width:1000px){
    max-width:984px;
  }
  margin-top: 20px;
}
.topic{
  height: 100%;
    position: relative;
    // border: 0.5px solid #0A191E;
    // border-radius: 20px;
    // width:98%;
    // width: 355px;
    // height: 170px;
    // height: 190px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height:24.51px;
    // line-height: 25px;
    cursor: pointer;
    //To make name center
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background-color: #FEEBC1;
    padding:10px 15px;
    color:$topic-heading-color;
    border-radius: 10px;
    span{
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color:#4A4A4A;
      font-size:16px;
      line-height:21.79px;
      // margin-top:-10px;
    }
  }
  .topic-name-percentage{
    display: flex;
    justify-content: space-between;
    
  }
  // .topic-follow{
  //   background: $topic-follow;
  //   border-radius: 0px 0px 20px 20px;
  // }
  // .topic-unfollow{
  //   background: $topic-unfollow;
  //   border-radius: 0px 0px 20px 20px;
  // }
  .select-topic-heading{
    line-height: 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 32px;
    font-size: 24px !important;
  }
  .topic-section{
    @media screen and (min-width:1000px){
      max-width:984px;
    }

    // width: 100%;
    // padding: 20px 0 10px 30px;
  }
  // .next-button{
  //     background: $button-color !important;
  //     border-radius:25px !important;
  //     border:none !important;
  // }
  // .bottom{
  //   position:fixed;
  //   left:0;
  //   right: 0;
  //   bottom: 15px;      
  //   padding-bottom:10px;
  //   width: 92%;
  //   margin: auto;
  // }
  // .topic-without-icon{
  //   width:75%;
  //   margin:10px auto;
  //   display:inline-flex;
  //   justify-content: space-between;
  //   border-radius: 25px;
  //   padding-right: 0px !important;
  //   box-shadow: 0px 13px 9px -10px rgba(112, 112, 112, 0.25);
  //   border-radius: 19.5px;
  //   align-items: center;
  // }
  // .topic-bottom-follow{
  //   background-color: $topic-follow;
  //   border-radius:20px;
  //   padding:2px 8px;
  //   width:80px;
  //   height: 39px;
  //   padding: 7px;
  //   cursor: pointer;
  // }
  // .topic-bottom-unfollow{
  //   background-color: $topic-unfollow;
  //   border-radius:20px;
  //   padding:2px 8px;
  //   width:80px;
  //   height: 39px;
  //   padding: 7px;
  //   cursor: pointer;
  // }
  @media screen and (min-width:700px){
    .topic-page{
        background: $background-gradient;
        padding-bottom:80px;
    }
    .bottom{
      width: 28%;
    }
    .topic{
      width: 100%;
      height: 100%;
      padding:10px 20px 20px 20px;
    }
    .topic-section{
        // width:70%;
        padding:40px 58px 60px 48px;
        // padding-left:10px;
        margin: 50px auto;
        background:$white-background;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        // box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
        // border-radius: 30px;
        // padding-bottom: 20px;
        // div>div>div{
        //   background: red !important;
        //   border: 2px solid red;
        //   margin: 0 !important;
        // }
    }
  }