@import './color.scss';
.question-page{
  min-height: 100vh;
  position: relative;
  // padding-bottom:25px;
}
.container-content{
  .container{
      @media screen and (min-width:900px) {
          max-width: 788px;
      }
      padding:0 20px;
  }
  .quest{
      @media screen and (min-width:700px) {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      }
      background: $white-background;
      padding:0 30px;
      margin-bottom: 80px;
      
      border-radius: 5px;
  }
}
.quest{
  position: relative;
  .next-arrow{
      @media screen and(max-width:1000px) {
          right: -15px;
      }
      position: absolute;
      font-size: 54px;
      top:0;
      bottom: 0;
      right: -124px;
      display: flex;
      align-items: center;
  }
}
.question-title{
  word-break: break-word;
  p{
  word-break: break-word;
  }
}
.question2-image{
  width: 100%;
  // .img{
  //     max-width:100%;
  // }
}
.question2-image img{
  max-width: 100%;
  // height: 200px;
}
.topic-name-header {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #4A4A4A;
  span{
    font-size: 16px;
  }
}
.quit{
  font-family: 'Open Sans', sans-serif;
  font-size:16px;
}
.question-title{
  font-family: 'Open Sans', sans-serif;
  font-weight: 600px;
  font-size: 18px;
  line-height: 26px;
  label{
    font-weight: normal;
    font-size: 12px;
    padding-top: 1px;
  }
}
.showdiv{
  display: block;
}
.hide{
  display: none;
}
.alert-div{
  // width: 60%;
  margin:20px auto;
  // opacity: 0.6;
  background: $white-background;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.alert-div-visible{
  display: block;
}
.alert-div-hide{
  display: none;
}
.play-type-label{
  // position: absolute;
  // left:30px;
  // top: 0;
  background:$background-color;
  padding: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.options-new .checkbo label.parse {
  position: relative;
  text-indent: 0;
  padding-left: 30px;
  white-space: normal;
  line-height: 1.6;
}
.pretty{
  width: 100%;
  padding: 10px;
}
.options-new .checkbo label::before {
  top: 0;
  bottom: 0;
  margin: auto;
}
.pretty.p-smooth input:checked + .state .icon{
  margin-left:10px;
}
.options-new .pretty.p-icon .state  .icon {
  top: 0;
  bottom: 0;
  margin: auto;
}
// .pretty * {
//   display: inline;
// }
.state{
  display: inline;
  p{
    display: inline;
  }
}
// .div-center{
//   display: flex;
//   margin: 20px auto;
  
//   // width:51% !important;

// }
.enabled-div{
  pointer-events: visible;
  cursor: pointer;
  opacity: 1;
}
.disabled-div{
  pointer-events: none;
  opacity: 40%;
}
// .questionpage-topics-name-heading{
//   display: flex;
//   justify-content: space-between;
//   >div{
//     font-family: 'Open Sans', sans-serif;
//     font-weight: 600;
//     span{
//       font-size: 14px;
//     }
//   }
//   div.quit{
//     font-weight:400;
//   }
// }
.pretty .state label::before{
  border:1px solid $question-border;
}
.border-show{
  margin-left: 0;
  width: 100%;
}
.parse{
  font-family: 'Open Sans', sans-serif;
  // font-size: 14px;
  // line-height: 19px;
  // margin-top: 100px;

}
// div.ques{
//   display: contents;
// }
// .inputs{
//   height: 20px;
// }
// .next-question{
//   position: fixed;
//   left: 0;
//   right: 0;
//   bottom: 15px;
//   margin: auto;
//   background:$button-color !important;
//   border: none !important;
//   border-radius:100px !important;
//   width:70%;
// // }
// .answer{
  // position: relative;
  // border-radius: 30px 30px 0 0;
  // box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
//   .answer-header{
//     background-color: green;
//     // border-radius: 20px 20px 0 0;
//     padding: 8px;
//   }
//   .answer-section{
//     // background-color:$white-background;
//     min-height:30vh !important;
//     // min-width:20% !important;
//     // border-radius:0 0 30px 30px;
//     padding: 10px;
//   }
// }
// .answer-box{
//   // box-shadow: 0px 13px 9px -10px rgba(112, 112, 112, 0.25);
//   border-radius: 10px;
//   padding-left:-15px;
// }
// .question{
//     box-sizing: border-box;
//     // border-radius: 30px;
//     margin:30px auto;
//     width:90%;
//     margin:auto;
// }
// .question-header{
//     background: $question-header-color;
//     // border-radius: 20px 20px 0px 0px;
//     height: 40px;
//     text-align: center;
//     padding:5px;
// }
// .question-image{
//   max-height:300px;
//   max-width: 100%;
//   text-align: center;
// }
// .question-text{
//   font-family: 'Montserrat', sans-serif;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 22px;
//   padding-bottom:5px;
// }
.checkbox {
  // padding: 10px;
  margin: 10px auto;
  background: #FFFFFF;
  border: 0.5px solid $checkbox-color;
  box-sizing: border-box;
  // border-radius: 10px;

}

// .question-row{
//   margin: 0 !important;
// }
// .extraClass {
//   font-size: 20px !important;
//   pointer-events: auto !important;
//   &:hover {
//  visibility: visible !important;
//  opacity: 0.5 !important;
//   }
//  }

/////////

// .question-parent-box .question-image {
//   width: 80%;
//   display: block;
//   margin: auto;
// }
// .options-new {
//   // background: blue;
//   min-height:150px; 

// }
// .question-parent-box {
//   position: relative;
//   min-height:120px;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
//   border-radius: 5px;
// }
// .question-parent-box {
//   // background: red;
//   padding: 20px;
//   background: rgba(255,255,255,0.6);
//   height: inherit !important;
// }
// .new-row {
//   position: relative;
//   // background: lightblue;
//   width: 100%;
//   margin: auto;
//   padding-bottom: 50px;

// }
// .questionpage-topics-name-heading {
//   @media screen and (max-width:600px) {
//     width: 100%;
//     margin: auto;
//   }
//   width: 60%;
//   // padding: 0 50px;
//   margin: auto;
//   display: flex;
//   justify-content: space-between;
// }
// .answer-new .answer-header {
//   // background: rgb(120, 146, 120);
//   // color:red !important;
// }
// .question-parent-box {
//   @media screen and (min-width:900px) {
//     max-width: 900px;
//   }
//   // background: blue;
//   width: 60%;
//   height: 250px;
//   margin: auto;
//   // margin-left:200px;
//   // .question-new {
//   //     // background: lightcoral;
//   // }

// }
// .col .check {
//     // background:red;
// }
.answer-new {
    // background: green;
    background: white;
    opacity: 0.8;
    border-radius: 20px;
}
// .question-parent-box .next-arrow {
//   // background: white;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 54px;
  
// }
// .new-row .next-arrow{
//   @media screen and (max-width:600px) {
//     right: -50px;
//     z-index:1111;
//   }
//   // background: red;
//   display: flex;
//   align-items: center;
//   font-size:54px;
//   padding-right:30px;
//   position: absolute;
//   right: -150px;
//   top: 0;
//   bottom: 0;
// }
// .no-question-block{
//   display: flex;
//   height: 150px;
//   background: $white-background;
//   width: 70%;
//   margin: 50px auto;
//   justify-content: center;
//   align-items: center;
// }





////
@media screen and (min-width:700px){
  .div-center{
    width:51%;
    margin: 20px auto;
  }
  .answer{
    width:42% ;
    height:inherit ;
    margin: 18px;
    border: 1px solid #B7E7CB;
  // margin-top: -1px !important;
    // border-radius:30px;
    margin-right:98px;
    box-sizing: border-box;
    box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
  }
  .question-page{
    padding-bottom: 10px;
    background: $background-gradient;
  }
  // .question-parent-box{
  //   // margin-left:270px;
  // }
  div.ques{
    display: contents;
  }
  .question-section{
    padding: 20px 0;
  }
  // .question{
  //   // box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
  //   // border-radius: 30px
  // }
  .question-image{
    width:100%;
    height:200px;
  }
  .next-question{
    position: relative;
    width: 20%;
  }
  .border-show{
    border: 0.5px solid $question-border;
    box-sizing: border-box;
    box-shadow: 0px 15px 15px -5px rgba(112, 112, 112, 0.25);
    // border-radius: 30px;
    margin-left: 98px;
    width: 42%;
  }
  .border-hide{
    border:none;
  }
}


  