@import './color.scss';
.button-primary{
    background: #BC3E6F;
    box-shadow: 0px 4px 0px #A01C50;
    border-radius: 3px;
}

.footer-bottom{
    position: fixed !important;
    bottom: 0;
    width: 100%;
    background: rgba(255,255,255,1) !important;
}