$play-button:#31B9CC;
$button-color:#31B9CC;
$button-new-color:#BC3E6F;
$background-color:#FFFAEF;
$toggle-background:#F1F2F6;
$toggle-active:#FECA57;
$question-header-color: #FEEBC1;
$topic-follow:#31B9CC;
$topic-unfollow:#FEEBC1;
$selected-topics:#FEEBC1;
$browse-button:#FECA57;
// $white-background:#fff;
$white-background:rgba(255, 255, 255, 0.6);
$input-background-color:#FAFAFA;
$Tread-color:#4F4F4F;
$subtopic-label:#FEEBC1;
$topic-tab-color:#FEEBC1;
// $nav-shrink-color:#F1F2F6;
$nav-shrink-color:rgba(255,253,247,0.5);
$question-border:#31B9BB;
$checkbox-color:#31B9CC;
$background-gradient:linear-gradient(115deg, #FFFAEF 50%,#FFF4DD 50%);
// $background-gradient:linear-gradient(115deg, #f7ca69 50%,#c08d20 50%);
$footer-background:rgba(255,255,255,0.5);
$topic-heading-color:#BC3E6F;
$logo-theme-color:#BC3E6F;
